import React from "react"
import { Link } from "gatsby"

import { Container } from "../grid/grid"
import { CardCustom } from "../card/card"

import { replaceUnicode } from "../utils/utils"

export const AcfColCards = ({ data }) => {
  return (
    <div className="row row-0">
      {data &&
        data.acf.cards.map(card => {
          return (
            <div className="col-md-4 col-12">
              <CardCustom
                className="position-relative custom-card"
                imgHeight="h-ratio-1-1"
                img={card.background_image.mediaItemUrl}
              >
                <div className="overlay"></div>
                <Container className="children-body">
                  <h2 className="custom-title mb-4">{card.title}</h2>
                  <ul className="link-list">
                    {card.links.map(link => {
                      return (
                        <li className="link-item">
                          <Link to={link.link.url}>
                            <img
                              alt="list icon"
                              src={link.link_icon.mediaItemUrl}
                              className="link-icon"
                            />
                            {replaceUnicode(link.link.title)}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </Container>
              </CardCustom>
            </div>
          )
        })}
    </div>
  )
}

export default AcfColCards
