import React, { useContext } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { HeadingBasic } from "../../components/heading/heading"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import {
  WpGroup,
  Stats,
  Carousel,
} from "../../components/utils/render-functions"
import { Cover } from "../../components/cover/cover"
import { AcfIconList } from "../../components/acf-iconlist"
import { AcfColCards } from "../../components/acf-col-cards"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const AboutUsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "img_development-main.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imgDeltamas: file(relativePath: { eq: "imgDeltamas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      featured: file(relativePath: { eq: "img-featured-news-sample.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      overseas: file(relativePath: { eq: "mask.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      imgVideoCover: file(
        relativePath: { eq: "home/bg_home_cover-hero-video.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2880) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "about-us") {
          slug
          blocks {
            name
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreImageBlock
            ...CoreButtonBlock
            ...CoreShortcodeBlock
            ...CoreCoverBlock
            ...CoreGroupBlock
            ...AcfLabelValueBlock
            ...AcfGalleryBlock
            ...AcfColumnedCards
          }
          translation(language: ID) {
            title
            blocks {
              name
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreImageBlock
              ...CoreButtonBlock
              ...CoreShortcodeBlock
              ...CoreCoverBlock
              ...CoreGroupBlock
              ...AcfLabelValueBlock
              ...AcfGalleryBlock
              ...AcfColumnedCards
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const wordpress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const coverVideo =
    wordpress &&
    wordpress.find(x => x.name === "core/cover") &&
    wordpress.find(x => x.name === "core/cover") === wordpress[0] &&
    wordpress[0]
  const findCoverVideo = coverVideo
    ? coverVideo.innerBlocks.find(x => x.name === "core-embed/youtube")
      ? coverVideo.innerBlocks.find(x => x.name === "core-embed/youtube")
          .attributes.url
      : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
    : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"


  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={findCoverVideo}
        twitterImg={findCoverVideo}
        img={findCoverVideo}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {wordpress && (
        <>
          <Cover
            variant="basic"
            theme="dark"
            img={data.imgVideoCover.childImageSharp.fluid}
            imgHeight="h-400px h-md-500px w-100"
            imgOverlay="40"
            className="cover cover-full-top"
            type="video-popup"
            videoSrc={
              coverVideo
                ? coverVideo.innerBlocks.find(
                    x => x.name === "core-embed/youtube"
                  )
                  ? coverVideo.innerBlocks.find(
                      x => x.name === "core-embed/youtube"
                    ).attributes.url
                  : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
                : "https://ap-bismite-yk88.antikode.dev/app/uploads/2020/04/Sinar-Mas-Land.-Building-For-a-Better.mp4"
            }
            audio={false}
            title={coverVideo.innerBlocks[0].attributes.content}
          />

          {wordpress.map((item, i) => {
            switch (item.name) {
              case "acf/columned-cards":
                return <AcfColCards data={item} key={`${item.name}${i}`} />
              case "core/group":
                if (item.innerBlocks.find(x => x.name === "acf/iconlist")) {
                  return (
                    <section key={`value-${i}`} className="pb-main">
                      <div className="container container-md">
                        <HeadingBasic
                          h2={
                            item.innerBlocks.find(
                              x => x.name === "core/heading"
                            ).attributes.content
                          }
                        />
                        <AcfIconList data={item} />
                      </div>
                    </section>
                  )
                }
                return (
                  <div key={`group-${i}`}>
                    <div className="pb-main">
                      <WpGroup data={item} />
                    </div>
                  </div>
                )
              case "core/paragraph":
                return (
                  <div
                    key={`paragraph-${i}`}
                    className="container container-md"
                  >
                    <div className={i > 1 ? "" : "py-main"}>
                      <HeadingBasic text={[item.attributes.content]} />
                    </div>
                  </div>
                )
              case "acf/labelvalues":
                if (wordpress[i + 1].name === "core/image") {
                  return (
                    <div key={`lablevalues-${i}`}>
                      <Stats data={item} />
                    </div>
                  )
                } else {
                  return (
                    <div key={`lablevalues-${i}`} className="pb-main">
                      <Stats data={item} />
                    </div>
                  )
                }

              case "core-embed/youtube":
                return (
                  <div className="py-main">
                    <Cover
                      variant="basic"
                      theme="dark"
                      img={null}
                      imgHeight="h-400px h-md-500px w-100"
                      // imgOverlay="40"
                      className="cover cover-full-top"
                      type="video-popup"
                      videoSrc={item.attributes && item.attributes.url}
                      type={{ name: "core-embed/youtube" }}
                      audio={false}
                    />
                  </div>
                )
              case "core/cover":
                if (i > 0) {
                  return (
                    <div key={`cover-${i}`} className="pb-main">
                      <CoverFeatured
                        label="News"
                        img={
                          item.attributes.url ||
                          data.featured.childImageSharp.fluid
                        }
                        title={
                          item.innerBlocks[0] &&
                          item.innerBlocks[0].attributes.content
                        }
                        text={
                          item.innerBlocks[1] &&
                          item.innerBlocks[1].attributes.content
                            .split("<br>")
                            .join(" ")
                        }
                        btnText={"Baca Lebih Lanjut"}
                        btnLink={`/about-us${item.innerBlocks[2].innerBlocks[0].attributes.url}`}
                      />
                    </div>
                  )
                } else {
                  return <div key={`cover-${i}`}></div>
                }
              case "core/image":
                return (
                  <img
                    key={`image-${i}`}
                    alt="History"
                    src={item.attributes.url}
                    className="pb-main"
                    width="100%"
                  />
                )
              case "acf/acfgallery":
                return (
                  <div key={`innerblocks-carousel-${i}`} className="py-main">
                    <Carousel data={item} />
                  </div>
                )
              default:
                return <div key={`default-${i}`}></div>
            }
          })}
        </>
      )}
    </Layout>
  )
}

export default AboutUsPage
